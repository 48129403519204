<template>
    <div>
        <div class="main">
            <div class="balance"  @click="jumpGoods">
                <div class="balance_main">
                    <div class="title">餘額 Balance</div>
                    <div class="balance_amount">
                        <div class="amount"><span style="font-size:20px;">￡</span>{{$store.state.wallet}}</div>
                        <div class="tobuy">
                            <div style="position: absolute;left:-35px;top:18%;width:30px;height:30px;"><img src="../../assets/image/mall1.png" alt="" width="100%" height="100%"></div>
                            <div style="margin-right:18px;display: inline-block;font-weight: 500;color:#FEC501"><div>去兌換</div><div style="font-size:16px;">Redeem</div></div>
                            <div style="position: absolute;right:0;top:25%;width:14x;height:22px;"><img src="../../assets/image/aw_right1.png" alt="" width="100%" height="100%"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="detail_container">
                <div class="detail_title">
                    <div  style="width:5px;height: 27px;background: #6CB550;"></div>
                    <div  style="margin-left:10px;font-size:20px;">明細 <span style="font-size:16px;">History</span></div>
                </div>
                <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="沒有更多了"
                    @load="onLoad"
                    style="padding: 10px 15px;"
                >
                    <div style="border-bottom:1px solid #ccc" v-for="(item,index) in walletData" :key="index">
                        <div class="detail_flex" style="min-height: 30px;line-height: 20px;margin-top:10px;">
                            <div style="font-size: 16px;" v-if="item.transType == 0 || item.transType == 3 || item.transType == 5">訂單退款<span style="font-size: 12px;">(ORDER REFUND)</span></div>
                            <div style="font-size: 16px;" v-if="item.transType == 1">購買商品<span style="font-size: 12px;">(THE PURCHASE OF GOODS)</span></div>
                            <div style="font-size: 16px;" v-if="item.transType == 2">活動獎金<span style="font-size: 12px;">(ACTIVITY BOUNDS)</span></div> 
                            <!-- 活動獎金 》 发钱 -->
                            <div style="font-size: 16px;" v-if="item.transType == 4">訂單獎金<span style="font-size: 12px;">(ORDER BONUS)</span></div>
                            <div style="font-size: 16px;" v-if="item.transType == 6">扣减奖金<span style="font-size: 12px;">(CUT BONUS)</span></div>
                            <div style="font-size: 16px;" v-if="item.transType == 7">兌換商品<span style="font-size: 12px;">(REDEEM PRIZE)</span></div>
                            <div style="font-size: 16px;" v-if="item.transType == 8">扣减奖金<span style="font-size: 12px;">(CUT BONUS)</span></div>
                            <div style="font-size: 20px;color: #6CB550;" v-if="item.transType != 1 && item.transType != 7 && item.transType != 8 && item.transType != 6">+{{item.transAmount}}</div>
                            <div style="font-size: 20px;color: #DD001A;" v-if="item.transType == 1 || item.transType == 7 || item.transType == 8 || item.transType == 6">{{item.transAmount}}</div>
                        </div>
                        <div class="detail_flex" style="min-height:30px;">
                            <div style="font-size: 12px;color: #808080;">{{parseTime(item.transTime)}}</div>
                            <div style="font-size: 12px;color: #808080;word-break: break-all;">{{item.businessName}}</div>
                        </div>
                    </div>
                </van-list>
            </div>
        </div>
    </div>
</template>
<script>
import {getWalletList} from "../../api/wallet"
export default {
    data() {
        return {
            loading: false,
            finished:false,
            limit: 10,
            page: 0,
            walletData:[]
        }
    },
    created() {
        this.$store.dispatch('getWallet')
    },
    methods:{ 
        getWalletList() {
            let params = {
                "limit": this.limit,
                "page": this.page,
            }
            getWalletList(params).then(res => {
                this.loading = false;
                this.walletData = this.walletData.concat(res.data.list)
                if (res.data.list.length  == 0) {  //数据全部加载完成
                    this.finished = true;
                }else{
                    this.finished = false;
                }
            })
        },
        onLoad() {
            this.page++;
            this.getWalletList()
        },
        jumpGoods(){
            this.$router.push('/wallet/prize')
        }
    }
}
</script>
<style scoped>
    .main {
        width: 93%;
        margin: 15px auto;
        box-sizing: border-box;
    }
    .balance {
        background: url('../../assets/image/wallet_bg.png') no-repeat;
        background-size: 100% 100%;
        border-radius: 20px;
        box-sizing: border-box;
    }
    .balance_main {
        padding: 30px 40px;
        color: #6CB550;
        font-size: 18px;
        box-sizing: border-box;
    }
    .balance_amount {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 50px 0;
    }
    .amount {
        font-size: 30px;
        font-weight:600;
    }
    .tobuy {
       position: relative;
       font-size: 18px;
    }
    .detail_title {
        display: flex;
    }
    .detail_container {
        margin-top: 30px;
        margin-bottom: 60px;
        padding: 40px 0px;
        background:#fff;
        border-radius: 20px;
        box-sizing: border-box;
    }
    .detail_flex {
        display: flex;
        justify-content: space-between;
    }
</style>